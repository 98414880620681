import * as React from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const MainLayout = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
};
export default MainLayout;
