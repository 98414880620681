import * as React from "react";
import Container from "react-bootstrap/Container";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./BioBit.scss";

const BioBit = () => {
  const data = useStaticQuery(
    graphql`
      query {
        robProf: file(relativePath: { eq: "rob-profile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              height: 70
              placeholder: BLURRED
              formats: [WEBP]
              layout: FIXED
            )
          }
        }
        kirstProf: file(relativePath: { eq: "kirst-profile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              height: 70
              placeholder: BLURRED
              formats: [WEBP]
              layout: FIXED
            )
          }
        }
      }
    `
  );
  return (
    <section id="ourteam" className="bioBit">
      <Container className="text-center">
        <h2 className="sectionHeading">Our Team</h2>
      </Container>
      <Container className="bioBit__cont">
        <div className="bioCard d-flex flex-column">
          <div className="bioCard__top text-center">
            Robert Dittman, President and Instructor
          </div>
          <div className="here d-flex justify-content-center">
            <GatsbyImage
              className="bioCard__img"
              image={getImage(data.robProf)}
              alt="Robert Dittman picture"
            />
          </div>
          <div className="bioCard__description">
            Robert Dittman, a licensed attorney and expert shooter, received
            firearms training through the U.S. Marine Corps and U.S. Coast
            Guard. He enjoys target shooting and his preferred weapon is a
            Walther Arms PPK/S 22 LR.
          </div>
        </div>
        <div className="bioCard d-flex flex-column">
          <div className="bioCard__top text-center">
            Kirstiana Dittman, President and Instructor
          </div>
          <div className="here d-flex justify-content-center">
            <GatsbyImage
              className="bioCard__img"
              image={getImage(data.kirstProf)}
              alt="Robert Dittman picture"
            />
          </div>
          <div className="bioCard__description">
            Kirstiana Dittman, a former farm girl who learned to shoot with her
            father, a US Marine, obtained a license to carry and developed a
            training course with her husband. She enjoys distance shooting with
            a rifle and her preferred side arm is a Walther Arms P22.
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BioBit;
