import * as React from "react";
import type { HeadFC } from "gatsby";
import MainLayout from "../layouts/MainLayout/MainLayout";
import TitleBit from "./Index/TitleBit/TitleBit";
import TagBit from "./Index/TagBit/TagBit";
import BioBit from "./Index/BioBit/BioBit";
import ContactBit from "./Index/ContactBit/ContactBit";
import LocationsBit from "./Index/LocationsBit/LocationsBit";
import FaqBit from "./Index/FaqBit/FaqBit";
import MediaBit from "./Index/MediaBit/MediaBit";
import "./indexPage.scss";

const IndexPage = () => {
  return (
    <MainLayout>
      <TitleBit />
      <TagBit />
      <MediaBit />
      <LocationsBit />
      <BioBit />
      <FaqBit />
      <ContactBit />
    </MainLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <meta charSet="UTF-8" />
    <meta httpEquiv="cache-control" content="no-cache" />
    <meta name="description" content="a resource for economic discussion" />
    <meta
      name="keywords"
      content="shooting, training, gun safety, accessibility, blind"
    />
    <meta name="author" content="James Hernandez" />
    <meta name="robots" content="index, follow" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Blind Shooting, LLC</title>
  </>
);
